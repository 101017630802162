<template>
  <div>
  <EditCategory
    @editDetail="editCategory"
    @cancelEdit="cancelEdit"
    @updateSlugAlreadyExists="updateSlugAlreadyExists"
    :categoryId="categoryId"
    :slugAlreadyExists="slugAlreadyExists"
  />
  <ProductList
    :categoryId="categoryId"
  />
  </div>
</template>

<script>
  import EditCategory from "../../../components/WebStore/categories/edit";
  import ProductList from "../../../components/WebStore/categories/productList";
  import { mapActions } from 'vuex';

  export default {
    name: 'Edit-Category',
    components:{
      EditCategory,
      ProductList
    },
    data: () => ({
      categoryId: '',
      slugAlreadyExists: false,
    }),
    methods:{
      ...mapActions("category", [
        "updateCategoryDetails"
      ]),
      ...mapActions("ecommerce",["algoliaSync"]),
      editCategory(data){
        let self=this;
        let value = {
          id: this.categoryId,
          info: data
        };
        this.updateCategoryDetails(value).then(res => {
          self.algoliaSync()
          self.$vs.notify({
            title: "Category Edit",
            text: "Category edited successfully",
            color: "success",
          });
          this.slugAlreadyExists = false;
          self.$vs.loading.close();
          this.$router.push({ name: 'CategoryManagement'})
        }).catch((e)=>{
          self.$vs.notify({
            title: "Category Edit",
            text: `${e.data.message}`,
            color: "danger",
          });
          this.slugAlreadyExists = true;
        });
      },
      cancelEdit() {
        this.$router.push({ name: "CategoryManagement"});
      },
      updateSlugAlreadyExists() {
        this.slugAlreadyExists = false;
      },
    },
    async created() {
      this.categoryId = this.$route.params.categoryId;
    }
  };
</script>
